import React from 'react';
import { useParams } from 'react-router-dom';
import blogData from '../jsonData/blogData.json';

const BlogPostDetails = () => {
    const { postId } = useParams();
    const post = blogData.find(post => post.id === postId);

    if (!post) {
        return <div className="text-white text-center py-10">Post not found</div>;
    }

    return (
        <div className="max-w-7xl mx-auto p-4 md:p-8">
            <div className="bg-[#131313b3] rounded-lg shadow-md p-6 md:p-10">
                {/* Image */}
                <img
                    src={require(`../images/${post.image}`)}
                    alt={post.title}
                    className="w-full h-64 md:h-96 object-cover rounded-md mb-6"
                />

                {/* Title */}
                <h1 className="text-3xl md:text-5xl font-bold text-white mb-4">{post.title}</h1>

                {/* Description */}
                <p className="text-gray-300 text-lg md:text-xl leading-relaxed">{post.description}</p>
            </div>
        </div>
    );
};

export default BlogPostDetails;