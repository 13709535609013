import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import bgImage from "../images/hero-bg.png";
import mobileImage from "../images/mobile-img.png";
import AOS from "aos";
import "aos/dist/aos.css";
import StoreButton from "../subcomponents/StoreButton";
import { faApple, faGoogle, faGooglePlay } from "@fortawesome/free-brands-svg-icons";

const Hero = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <div
        className="max-w-7xl mx-auto rounded-[20px] lg:rounded-[60px] p-[18px_18px_0px_18px] lg:p-[70px_70px_0px_70px] bg-cover text-center lg:text-left"
        data-aos="fade-up"
        style={{
          backgroundImage: `url(${bgImage})`, // Use the imported image
        }}
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 items-center">
          <div>
            <h1
              className="text-white text-[32px] lg:text-[64px] font-Inter leading-[42px] lg:leading-[72px] my-4 lg:my-10 font-medium"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              Instant Push Notifications
            </h1>
            <p
              className="text-[#ececfb] text-[14px] font-Inter font-light lg:w-3/4"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              Instant notifications on all your devices. Perfect for developers
              and enthusiasts with easy integration via Open API and tons of
              packages. Enjoy a 90-day free trial.
            </p>
            <NavLink
              to="/demo"
              className="inline-block min-w-52 text-center text-white border border-white p-[10px_30px] font-semibold mt-[30px] rounded"
            >
              Watch Demo
            </NavLink>
            <div className="flex flex-col items-center space-y-2 mt-6 lg:flex-row lg:space-y-0 lg:space-x-2">
              <StoreButton text="Download iOS" icon={faApple} iconSize={22} />
              <StoreButton text="Download Android" icon={faGooglePlay} iconSize={20} />
            </div>
          </div>
          <div className="text-center mt-6 lg:mt-0">
            <img
              src={mobileImage}
              alt="img"
              className="mx-auto"
              data-aos="fade-up"
              data-aos-duration="3000"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
