import React, { useEffect } from "react";
import GradientImg from "../images/simple-msg.png";
import AOS from "aos";
import "aos/dist/aos.css";

const Demo = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <div className="max-w-7xl mx-auto mb-11">
        <div
          className="grid grid-cols-1 items-center"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <iframe
            className="rounded-[20px] lg:rounded-[20px] border-2 border-[#01a56d]"
            width="100%"
            height="550px"
            src="https://www.youtube.com/embed/LjZxeSne67E?si=uJ1qGIcnAYkNfAng"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default Demo;
