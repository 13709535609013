import React, { useEffect } from "react";
import GradientImg from "../images/simple-msg.png";
import AOS from "aos";
import "aos/dist/aos.css";

const Pricing = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <div
        className="max-w-7xl mx-auto rounded-[20px] lg:rounded-[60px] p-[18px] lg:p-[70px] bg-cover text-center lg:text-left"
        data-aos="fade-up"
        style={{
          backgroundImage: `url(${GradientImg})`, // Use the imported image
        }}
      >
        <div className="grid grid-cols-1 items-center">
          <div className="text-center">
            <h1
              className="text-white text-[32px] lg:text-[64px] font-Inter leading-[42px] lg:leading-[72px] my-4 lg:my-10 font-medium lg:w-3/5 mx-auto"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              Pricing Plans Tailored for You
            </h1>
            <p
              className="text-[#ececfb] text-[14px] font-Inter font-light lg:w-2/5 mx-auto"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              Every package will give you access to all features of the
              platform. Download the app now and activate your plan in there!
            </p>
            <a
              href=""
              className="bg-[#121212] rounded-lg text-white font-Inter font-medium text-[14px] p-[12px_18px] inline-block hover:bg-slate-800"
            >
              Android App
            </a>
            <a
              href=""
              className="bg-[#121212] rounded-lg text-white font-Inter font-medium text-[14px] p-[12px_18px] inline-block ml-4 mt-7 hover:bg-slate-800"
            >
              iOS App
            </a>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-7">
          <div
            className="bg-[#131313b3] w-full rounded-2xl border border-[#ffffff40] p-3 lg:p-[25px_18px] mx-auto lg:mx-0 mt-10 lg:ml-auto"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <h4 className="font-Inter text-[32px] text-white font-normal mt-3">
              Free Trial
            </h4>
            <p className="text-[#d8d5d1cc] text-sm font-Inter">
              Enjoy 3 months with no limits!
            </p>
            <hr className="border-[#ffffff1a]" />
            <div className="text-[58px] text-[#B5B8BC] font-inter font-bold">
              €0.00
              <span className="text-2xl color-[#B5B8BC] font-Inter font-bold">
                /mo
              </span>
            </div>
            <p className="text-[#d8d5d1cc] text-sm font-Inter">
              Enjoy all of our features free for 3 months, no credit card
              required.
            </p>
          </div>
          <div
            className="bg-[#131313b3] w-full rounded-2xl border-2 border-[#01a56d] p-3 lg:p-[25px_18px] mx-auto lg:mx-0 mt-10 lg:ml-auto"
            data-aos="fade-down"
            data-aos-duration="3000"
          >
            <h4 className="font-Inter text-[32px] text-white font-normal mt-3">
              One Off
            </h4>
            <p className="text-[#d8d5d1cc] text-sm font-Inter">
              Pay once, use it forever!
            </p>
            <hr className="border-[#ffffff1a]" />
            <div className="text-[58px] text-[#B5B8BC] font-inter font-bold">
              €35.99
              <span className="text-2xl color-[#B5B8BC] font-Inter font-bold"></span>
            </div>
            <p className="text-[#d8d5d1cc] text-sm font-Inter">
              Pay once and then sit back and relax. No additional payments
              required. Enjoy JustPush forever!
            </p>
          </div>
          <div
            className="bg-[#131313b3] w-full rounded-2xl border border-[#ffffff40] p-3 lg:p-[25px_18px] mx-auto lg:mx-0 mt-10 lg:ml-auto"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <h4 className="font-Inter text-[32px] text-white font-normal mt-3">
              Yearly
            </h4>
            <p className="text-[#d8d5d1cc] text-sm font-Inter">
              If you want to be flexible.
            </p>
            <hr className="border-[#ffffff1a]" />
            <div className="text-[58px] text-[#B5B8BC] font-inter font-bold">
              €15.99
              <span className="text-2xl color-[#B5B8BC] font-Inter font-bold">
                /year
              </span>
            </div>
            <p className="text-[#d8d5d1cc] text-sm font-Inter">
              Enjoy using JustPush until you cancel your subscription. You will
              still have access to the app until your current subscription
              period expires.
            </p>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto p-3">
        <p
          className="text-[#d8d5d1cc] text-sm font-Inter text-center pt-10"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          Our pricing plans are designed to elevate your experience and fit
          seamlessly into your life.
        </p>
        <div
          className="rounded-2xl bg-[#191919db] p-6 mt-4 mb-12"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <div className="grid grid-cols-1 lg:grid-cols-2 items-center">
            <div>
              <h3 className="text-[32px] font-Inter text-white">
                Need more info?
              </h3>
              <p className="text-[#d8d5d1cc] text-sm font-Inter">
                Contact us and we will give you everything you need
              </p>
            </div>
            <div className="text-right">
              <a
                href="mailto:info@justpush.io"
                className="bg-white hover:opacity-85 text-black font-Inter p-[10px_30px] rounded-md"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
