import React, { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  
  const onItemPress = useCallback(() => setIsOpen(false), []);

  return (
    <>
      <header className="py-4 sticky top-0 left-0 right-0 z-50">
        <div className="container max-w-[576px] mx-auto">
          <div
            className="flex justify-between lg:items-center rounded-lg py-[5px] px-[14px] lg:gap-4 flex-col lg:flex-row"
            style={{
              WebkitBackdropFilter: "blur(20px)",
              backdropFilter: "blur(20px)",
              backgroundColor: "rgba(33, 33, 33, 0.3)",
            }}
          >
            {/* Logo Section */}
            <NavLink to="/" onClick={onItemPress}>
              <div className="flex items-center mr-2">
                {/* Icon in a circle */}
                <div className="shrink-0">
                  <img
                    src="./images/logo-org.svg"
                    alt="logo"
                    className="h-12"
                  />
                </div>
                <span className="text-[#0891fe] text-md lg:text-2xl font-bold font-Inter">
                  Just<label className="text-white cursor-pointer">Push</label>
                </span>
              </div>
            </NavLink>
            <div className="flex items-center lg:order-2">
              {/* <div className="hidden mt-2 mr-4 sm:inline-block">
                <span></span>
              </div> */}

              <button
                type="button"
                className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden focus:outline-none focus:ring-2 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 absolute right-[16px] top-[8px]"
                onClick={() => setIsOpen(!isOpen)}
                aria-controls="mobile-menu-2"
                aria-expanded={isOpen ? "true" : "false"}
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </button>
            </div>
            {/* Navigation Links */}
            <div
              className={`items-center justify-between w-full lg:flex lg:w-auto lg:order-1 ${
                isOpen ? "block" : "hidden"
              }`}
              id="mobile-menu-2"
            >
              <nav className="flex flex-col mt-5 font-medium lg:flex-row lg:space-x-3 lg:mt-0 lg:space-y-0 space-y-4">
                <NavLink
                  to="/"
                  onClick={onItemPress}
                  className={({ isActive }) =>
                    isActive
                      ? "text-white text-xs lg:text-sm bg-black hover:bg-slate-900 px-2 py-1 rounded font-Inter"
                      : "text-white text-xs lg:text-sm hover:text-gray-400 font-Inter px-2 py-1"
                  }
                >
                  Product
                </NavLink>
                <NavLink
                  to="/features"
                  onClick={onItemPress}
                  className={({ isActive }) =>
                    isActive
                      ? "text-white text-xs lg:text-sm bg-black hover:bg-slate-900 px-2 py-1 rounded font-Inter"
                      : "text-white text-xs lg:text-sm hover:text-gray-400 font-Inter px-2 py-1"
                  }
                >
                  Features
                </NavLink>
                <NavLink
                  to="/pricing"
                  onClick={onItemPress}
                  className={({ isActive }) =>
                    isActive
                      ? "text-white text-xs lg:text-sm bg-black hover:bg-slate-900 px-2 py-1 rounded font-Inter"
                      : "text-white text-xs lg:text-sm hover:text-gray-400 font-Inter px-2 py-1"
                  }
                >
                  Pricing
                </NavLink>
                <NavLink
                  to="/blog"
                  onClick={onItemPress}
                  className={({ isActive }) =>
                    isActive
                      ? "text-white text-xs lg:text-sm bg-black hover:bg-slate-900 px-2 py-1 rounded font-Inter"
                      : "text-white text-xs lg:text-sm hover:text-gray-400 font-Inter px-2 py-1"
                  }
                >
                  Blog
                </NavLink>
                <NavLink
                  to="/demo"
                  onClick={onItemPress}
                  className={({ isActive }) =>
                    isActive
                      ? "text-white text-xs lg:text-sm bg-black hover:bg-slate-900 px-2 py-1 rounded font-Inter"
                      : "text-white text-xs lg:text-sm hover:text-gray-400 font-Inter px-2 py-1"
                  }
                >
                  Demo
                </NavLink>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
