import React, { useEffect } from "react";
import GradientImg from "../images/simple-msg.png";
import blogData from "../jsonData/blogData.json";
import AOS from "aos";
import "aos/dist/aos.css";
import { NavLink } from "react-router-dom";

const Blog = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <div
        className="max-w-7xl mx-auto rounded-[20px] lg:rounded-[60px] p-[18px] lg:p-[70px] bg-cover text-center lg:text-left"
        data-aos="fade-up"
        style={{
          backgroundImage: `url(${GradientImg})`, // Use the imported image
        }}
      >
        <div className="grid grid-cols-1 items-center">
          <div className="text-center">
            <h1
              className="text-white text-[32px] lg:text-[64px] font-Inter leading-[42px] lg:leading-[72px] mb-4 lg:mb-10 font-medium"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              Insights Hub
            </h1>
            <p
              className="text-[#ececfb] text-[14px] font-Inter font-light lg:w-2/5 mx-auto"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              Welcome to our Insights Hub, where knowledge meets finance.
              Explore a wealth of articles curated to guide you through the
              intricacies
            </p>
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto p-3">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center gap-7 mt-10">
          {blogData.map((blog, index) => (
            <NavLink
              key={index}
              to={"/blog/" + blog.id}
              className={({ isActive }) =>
                isActive
                  ? "text-white text-xs lg:text-sm bg-black hover:bg-slate-900 px-2 py-1 rounded font-Inter"
                  : "text-white text-xs lg:text-sm hover:text-gray-400 font-Inter px-2 py-1"
              }
            >
              <div className="mb-10" data-aos="fade-up" data-aos-duration="3000">
                <div className="h-[300px]">
                  <img
                    src={require(`../images/${blog.image}`)}
                    alt="img"
                    className="object-cover h-full w-full rounded-md"
                  />
                </div>
                <h3 className="text-[22px] font-Inter text-white mt-3">
                  {blog.title}
                </h3>
                <p className="text-[#d8d5d1cc] text-sm font-Inter mt-2">
                  {blog.description}
                </p>
              </div>
            </NavLink>
          ))}
        </div>
      </div>
    </>
  );
};

export default Blog;
