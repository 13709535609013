import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StoreButton = ({ icon, iconSize = 20, text, onClick, variant }) => {
  return (
    <button
      className={`${variant === 'secondary' ? 'border border-white' : ''} flex justify-center items-center bg-black hover:bg-slate-800 text-white font-bold py-3 px-4 rounded-[4px] focus:outline-none focus:shadow-outline flex items-center space-x-2 w-100 min-w-52`}
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={icon}
        className={`text-[${iconSize}px] text-white`}
      />
      <p>{text}</p>
    </button>
  );
};

export default StoreButton;
