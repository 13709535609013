import React from "react";
import Hero from "./Hero";
import FeatureBlock from "./FeatureBlock";
import SimpleMessage from "./SimpleMessage";

const Home = () => {
  return (
    <>
      <div className="main-home-page">
        <Hero />
        <FeatureBlock />
        <SimpleMessage />
      </div>
    </>
  );
};

export default Home;
