import React, { useEffect } from "react";
import bgImage from "../images/hero-bg.png";
import mobileImage from "../images/features-img.png";
import cardImg from "../images/card-bg.jpg";
import featuresImg1 from "../images/features-img-1.png";
import featuresImg2 from "../images/features-img-2.png";
import featuresImg3 from "../images/features-img-3.png";
import notSimpleMsg from "../images/simple-msg.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import mobileImgSmart from "../images/smart-system.png";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  faP,
  faHourglass,
  faImage,
  faRocket,
  faCheck,
  faBell,
  faT,
  faVolumeXmark,
  faFilter,
  faMusic,
  faUserPlus,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";

const Features = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      {/* HERO */}
      <div
        className="p-[18px_18px_0px_18px] lg:p-[70px_70px_0px_70px] bg-cover text-center lg:text-left"
        style={{
          backgroundImage: `url(${bgImage})`, // Use the imported image
        }}
        data-aos="fade-up"
      >
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 items-center">
            <div className="text-center">
              <h1
                className="text-white text-[32px] lg:text-[64px] font-Inter leading-[42px] lg:leading-[72px] my-4 lg:my-10 font-medium lg:w-[574px] mx-auto"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                Discover the Power of JustPush
              </h1>
              <p
                className="text-[#ececfb] text-[14px] font-Inter font-light lg:w-[420px] mx-auto"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                Welcome to the heart of innovation! Explore the extraordinary
                features that define JustPush and allow you sending push
                notifications from any app!
              </p>
              <a
                href="#"
                className="inline-block text-white border border-white p-[10px_30px] font-semibold mt-[30px] rounded"
              >
                Watch Demo
              </a>
            </div>
            <div className="text-center mt-6 lg:mt-9">
              <img
                src={mobileImage}
                alt="img"
                className="mx-auto relative lg:right-[-42px]"
                data-aos="fade-up"
                data-aos-duration="3000"
              />
            </div>
          </div>
        </div>
      </div>
      {/* CARDS */}
      <div className="max-w-7xl mx-auto mt-32">
        <h2
          className="text-[32px] lg:text-[72px] text-[#ffffffc2] font-Inter text-center"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          JustPush Features Unleashed
        </h2>
        <p
          className="text-center text-[#ececfb] font-Inter text-[14px] lg:w-1/3 mx-auto"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          Dive into the core of JustPush brilliance with our standout features.
          From real-time alerts keeping you informed, smart messages and call to
          actions.
        </p>
        {/* LEFT RIGHT SECTIONS */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-7 p-3">
          <div data-aos="fade-up" data-aos-duration="3000">
            <div className="mt-6 lg:mt-32">
              <div className="relative">
                <img src={cardImg} alt="img" className="rounded-[20px]" />
                <div className="absolute left-0 right-0 bottom-0 ">
                  <img src={featuresImg1} alt="img" />
                </div>
              </div>
            </div>
            <div className="mt-5 lg:mt-8 text-center">
              <h3 className="text-[32px] text-[#fff] font-Inter leading-[38px]">
                Create Topics
              </h3>
              <p className="text-[#d8d5d1cc] font-Inter text-[14px] mb-7 mt-3">
                We designed out SDK to allow you creating new topics to group
                your Push Messages. When the topics are ready, you can invite
                new members to join.
              </p>
            </div>
          </div>
          <div data-aos="fade-down" data-aos-duration="3000">
            <div className="mt-6 lg:mt-32">
              <div className="relative">
                <img src={cardImg} alt="img" className="rounded-[20px]" />
                <div className="absolute left-0 right-0 bottom-0 ">
                  <img src={featuresImg2} alt="img" />
                </div>
              </div>
            </div>
            <div className="mt-5 lg:mt-8 text-center">
              <h3 className="text-[32px] text-[#fff] font-Inter leading-[38px]">
                Send Push Messages
              </h3>
              <p className="text-[#d8d5d1cc] font-Inter text-[14px] mb-7 mt-3">
                Using the SDK, select the desired topic and send push messages.
                Every Push Message will trigger a push notification for every
                member of that topic, even when the app is closed or not.
              </p>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-duration="3000">
            <div className="mt-6 lg:mt-32">
              <div className="relative">
                <img src={cardImg} alt="img" className="rounded-[20px]" />
                <div className="absolute left-0 right-0 bottom-0 ">
                  <img src={featuresImg3} alt="img" />
                </div>
              </div>
            </div>
            <div className="mt-5 lg:mt-8 text-center">
              <h3 className="text-[32px] text-[#fff] font-Inter leading-[38px]">
                Take Actions
              </h3>
              <p className="text-[#d8d5d1cc] font-Inter text-[14px] mb-7 mt-3">
                Simple messages are boring. You can add call to action inside of
                the messages you send. Wait for the topic's members to interact
                with them.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* APP CARDS */}
      <div>
        <div className="max-w-7xl mx-auto mt-32 p-[12px_12px_0px_12px]">
          <h2
            className="text-[32px] lg:text-[72px] text-[#ffffffc2] font-Inter text-center"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            Discover the Power of the App
          </h2>
          <p
            className="text-center text-[#ececfb] font-Inter text-[14px] lg:w-1/3 mx-auto"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            Explore the depth of our app and unlock its full potential. Our app
            offers a range of features designed to help you.
          </p>
          {/* 3 SM CARDS */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-11 mt-10 mb-16">
            <div className="w-100 relative">
              <div
                className="max-w-[306px] py-5 text-center mx-auto mt-10"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div>
                  <FontAwesomeIcon
                    icon={faBell}
                    className="text-[32px] text-[#c90ee8]"
                  />
                </div>
                <h4 className="font-Inter text-2xl text-white font-medium mt-3">
                  Stay Notified
                </h4>
                <p className="text-[#d8d5d1cc] text-sm font-Inter mt-3">
                  Stay tuned to the topics you are interested in. We'll send a
                  push notification when you receive a new Push Message.
                </p>
              </div>
              <div
                className="max-w-[306px] py-5 text-center mx-auto mt-10"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div>
                  <FontAwesomeIcon
                    icon={faRocket}
                    className="text-[32px] text-[#c90ee8]"
                  />
                </div>
                <h4 className="font-Inter text-2xl text-white font-medium mt-3">
                  Take Actions
                </h4>
                <p className="text-[#d8d5d1cc] text-sm font-Inter mt-3">
                  Every message can have some actions attached. Just tap on it.
                  It's that easy!
                </p>
              </div>
              <div
                className="max-w-[306px] py-5 text-center mx-auto mt-10"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div>
                  <FontAwesomeIcon
                    icon={faP}
                    className="text-[32px] text-[#c90ee8]"
                  />
                </div>
                <h4 className="font-Inter text-2xl text-white font-medium mt-3">
                  Custom Priorities
                </h4>
                <p className="text-[#d8d5d1cc] text-sm font-Inter mt-3">
                  We all have messages that vary in importance. Attach a
                  priority level to your JustPush message so that topic members
                  can see its importance before reading it.
                </p>
              </div>
              <div
                className="absolute top-0 right-0 hidden lg:block"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                  opacity: 1,
                  width: 2,
                  height: "100%",
                  backgroundImage:
                    "linear-gradient(rgba(255, 255, 255, 0), #fff 25%, #fff 75%, rgba(255, 255, 255, 0))",
                }}
              ></div>
            </div>

            <div className="w-100 relative">
              <div
                className="max-w-[475px] py-5 text-center mx-auto mt-10"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div>
                  <FontAwesomeIcon
                    icon={faVolumeXmark}
                    className="text-[32px] text-[#c90ee8]"
                  />
                </div>
                <h4 className="font-Inter text-2xl text-white font-medium mt-3">
                  Full Control
                </h4>
                <p className="text-[#d8d5d1cc] text-sm font-Inter mt-3">
                  Not every topic or push message is important. You can stop
                  receiving push notification based on priority, quiet hours or
                  you can mute or even quit a topic.
                </p>
              </div>
              <div
                className="max-w-[475px] py-5 text-center mx-auto mt-10"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div>
                  <FontAwesomeIcon
                    icon={faFilter}
                    className="text-[32px] text-[#c90ee8]"
                  />
                </div>
                <h4 className="font-Inter text-2xl text-white font-medium mt-3">
                  Filtering
                </h4>
                <p className="text-[#d8d5d1cc] text-sm font-Inter mt-3">
                  Sometimes a topic can get crowded. Find older messages by
                  filtering based on date or priority.
                </p>
              </div>
              <div
                className="max-w-[475px] py-5 text-center mx-auto mt-10"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div>
                  <FontAwesomeIcon
                    icon={faImage}
                    className="text-[32px] text-[#c90ee8]"
                  />
                </div>
                <h4 className="font-Inter text-2xl text-white font-medium mt-3">
                  Images
                </h4>
                <p className="text-[#d8d5d1cc] text-sm font-Inter mt-3">
                  A picture says more than a thousand words. Attach images to
                  your JustPush messages to provide additional context.
                </p>
              </div>
              <div
                className="max-w-[475px] py-5 text-center mx-auto mt-10"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div>
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="text-[32px] text-[#c90ee8]"
                  />
                </div>
                <h4 className="font-Inter text-2xl text-white font-medium mt-3">
                  Acknowledgements
                </h4>
                <p className="text-[#d8d5d1cc] text-sm font-Inter mt-3">
                  Is your message so important that it needs to be seen by the
                  topic members? Request acknowledgment. We'll send them extra
                  reminders until the message is acknowledged.
                </p>
              </div>
            </div>
            <div className="w-100 relative">
              <div
                className="max-w-[306px] py-5 text-center mx-auto mt-10"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div>
                  <FontAwesomeIcon
                    icon={faMusic}
                    className="text-[32px] text-[#c90ee8]"
                  />
                </div>
                <h4 className="font-Inter text-2xl text-white font-medium mt-3">
                  Custom Sounds
                </h4>
                <p className="text-[#d8d5d1cc] text-sm font-Inter mt-3">
                  Recognize the push messages based on sound. Based on the push
                  message priority, you can chose how the push notification will
                  sound.
                </p>
              </div>
              <div
                className="max-w-[306px] py-5 text-center mx-auto mt-10"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div>
                  <FontAwesomeIcon
                    icon={faUserPlus}
                    className="text-[32px] text-[#c90ee8]"
                  />
                </div>
                <h4 className="font-Inter text-2xl text-white font-medium mt-3">
                  Invite Members{" "}
                  <span className="text-[#0891fe] text-[18px]">(SOON)</span>
                </h4>
                <p className="text-[#d8d5d1cc] text-sm font-Inter mt-3">
                  Invite new members to join a topic using an invitation link or
                  a QR code.
                </p>
              </div>
              <div
                className="max-w-[306px] py-5 text-center mx-auto mt-10"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div>
                  <FontAwesomeIcon
                    icon={faPlay}
                    className="text-[32px] text-[#c90ee8]"
                  />
                </div>
                <h4 className="font-Inter text-2xl text-white font-medium mt-3">
                  Buttons
                </h4>
                <p className="text-[#d8d5d1cc] text-sm font-Inter mt-3">
                  A message is just the start, add interactions through simple
                  buttons or groups, allowing your users to interact with your
                  JustPush messages.
                </p>
              </div>
              <div
                className="absolute top-0 left-0 hidden lg:block"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                  opacity: 1,
                  width: 2,
                  height: "100%",
                  backgroundImage:
                    "linear-gradient(rgba(255, 255, 255, 0), #fff 25%, #fff 75%, rgba(255, 255, 255, 0))",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
