import React, { useEffect } from "react";
import Header from "./components/Header"; // Adjust the import path as necessary
import Home from "./components/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Pricing from "./components/Pricing";
import Features from "./components/Features";
import Blog from "./components/Blog";
import Demo from "./components/Demo";
import Footer from "./components/Footer";
import ReactGA from "react-ga4";
import BlogPostDetails from "./components/BlogPostDetails";


function App() {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
  }, [])

  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Home />
              </>
            }
          />
          <Route path="/features" element={<Features />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/blog/:postId" element={<BlogPostDetails />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
