import React, { useEffect } from "react";
import notSimpleMsg from "../images/simple-msg.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faP,
  faHourglass,
  faImage,
  faRocket,
  faCheck,
  faBell,
  faT,
} from "@fortawesome/free-solid-svg-icons";

import mobileImgSmart from "../images/smart-system.png";
import AOS from "aos";
import "aos/dist/aos.css";

const SimpleMessage = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <div
        className="bg-cover overflow-y-hidden mt-16"
        style={{
          backgroundImage: `url(${notSimpleMsg})`, // Use the imported image
        }}
      >
        <div className="max-w-7xl mx-auto mt-12 p-[12px_12px_0px_12px]">
          <h2
            className="text-[32px] lg:text-[72px] text-[#ffffffc2] font-Inter text-center"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            Not simple a message
          </h2>
          <p
            className="text-center text-[#ececfb] font-Inter text-[14px] lg:w-1/3 mx-auto"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            A simple message would be boring, right? Check these nice properties
            you can embed directly in your messages.
          </p>

          {/* SM CARDS */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-11">
            <div className="w-100" data-aos="fade-up" data-aos-duration="3000">
              <div className="bg-[#131313b3] max-w-[400px] rounded-2xl border border-[#ffffff40] p-3 lg:p-[25px_18px] text-center mx-auto lg:mx-0 mt-10 lg:ml-auto">
                <div className="">
                  <FontAwesomeIcon
                    icon={faHourglass}
                    className="text-[32px] text-[#c90ee8]"
                  />
                </div>
                <h4 className="font-Inter text-2xl text-white font-medium mt-3">
                  Expiration Time
                </h4>
                <p className="text-[#d8d5d1cc] text-sm font-Inter mt-3">
                  Nothing lasts forever, and neither do JustPush messages with
                  an expiration time. Limit the time a message is available.
                </p>
              </div>
            </div>
            <div className="w-100 " data-aos="fade-up" data-aos-duration="3000">
              <div className="bg-[#131313b3] max-w-[400px] rounded-2xl border border-[#ffffff40] p-3 lg:p-[25px_18px] text-center mx-auto lg:mx-0 lg:mt-10 lg:mr-auto">
                <div className="">
                  <FontAwesomeIcon
                    icon={faP}
                    className="text-[32px] text-[#c90ee8]"
                  />
                </div>
                <h4 className="font-Inter text-2xl text-white font-medium mt-3">
                  Priority
                </h4>
                <p className="text-[#d8d5d1cc] text-sm font-Inter mt-3">
                  We all have messages that vary in importance. Attach a
                  priority level to your JustPush message so that topic members
                  can see its importance before reading it.
                </p>
              </div>
            </div>
          </div>
          {/* 3 SM CARDS */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-11">
            <div className="w-100">
              <div
                className="bg-[#131313b3] max-w-[400px] rounded-2xl border border-[#ffffff40] p-3 lg:p-[25px_18px] text-center  mx-auto lg:mx-0 mt-10 lg:ml-auto relative lg:mr-[-50px]"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div className="">
                  <FontAwesomeIcon
                    icon={faImage}
                    className="text-[32px] text-[#c90ee8]"
                  />
                </div>
                <h4 className="font-Inter text-2xl text-white font-medium mt-3">
                  Images
                </h4>
                <p className="text-[#d8d5d1cc] text-sm font-Inter mt-3">
                  A picture says more than a thousand words. Attach images to
                  your JustPush messages to provide additional context.
                </p>
              </div>
              <div
                className="bg-[#131313b3] max-w-[400px] rounded-2xl border border-[#ffffff40] p-3 lg:p-[25px_18px] text-center  mx-auto lg:mx-0 mt-10 lg:ml-auto"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div>
                  <FontAwesomeIcon
                    icon={faRocket}
                    className="text-[32px] text-[#c90ee8]"
                  />
                </div>
                <h4 className="font-Inter text-2xl text-white font-medium mt-3">
                  Call To Actions
                </h4>
                <p className="text-[#d8d5d1cc] text-sm font-Inter mt-3">
                  A message is just the start, add interactions through simple
                  buttons or groups, allowing your users to interact with your
                  JustPush messages.
                </p>
              </div>
              <div
                className="bg-[#131313b3] max-w-[400px] rounded-2xl border border-[#ffffff40] p-3 lg:p-[25px_18px] text-center  mx-auto lg:mx-0 mt-10 lg:ml-auto"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div>
                  <FontAwesomeIcon
                    icon={faBell}
                    className="text-[32px] text-[#c90ee8]"
                  />
                </div>
                <h4 className="font-Inter text-2xl text-white font-medium mt-3">
                  Push Notification
                </h4>
                <p className="text-[#d8d5d1cc] text-sm font-Inter mt-3">
                  Yes, it's that easy! Of course we automatically send a push
                  notification for every sent message.
                </p>
              </div>
            </div>
            <div className="order-3 lg:order-2">
              <div
                className="mt-11 text-center lg:text-left"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <img
                  src={mobileImgSmart}
                  alt="img"
                  className="relative lg:w-[130%] lg:max-w-[130%]"
                />
              </div>
            </div>
            <div className="w-100 order-2 lg:order-3">
              <div
                className="bg-[#131313b3] max-w-[400px] rounded-2xl border border-[#ffffff40] p-3 lg:p-[25px_18px] text-center mx-auto lg:mx-0 lg:mt-10 relative lg:ml-[-50px]"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div>
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="text-[32px] text-[#c90ee8]"
                  />
                </div>
                <h4 className="font-Inter text-2xl text-white font-medium mt-3">
                  Acknowledgement
                </h4>
                <p className="text-[#d8d5d1cc] text-sm font-Inter mt-3">
                  Is your message so important that it needs to be seen by the
                  topic members? Request acknowledgment. We'll send them extra
                  reminders until the message is acknowledged.
                </p>
              </div>
              <div
                className="bg-[#131313b3] max-w-[400px] rounded-2xl border border-[#ffffff40] p-3 lg:p-[25px_18px] text-center mx-auto lg:mx-0 mt-10 lg:ml-auto"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div>
                  <FontAwesomeIcon
                    icon={faT}
                    className="text-[32px] text-[#c90ee8]"
                  />
                </div>
                <h4 className="font-Inter text-2xl text-white font-medium mt-3">
                  Text
                </h4>
                <p className="text-[#d8d5d1cc] text-sm font-Inter mt-3">
                  Ok Ok, we do simple messages as well.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SimpleMessage;
