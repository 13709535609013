import React, { useEffect } from "react";
import cardImg from "../images/card-bg.jpg";
import cardShortOne from "../images/OnlyMessage.png";
import cardShortTopic from "../images/TopicsCreate.png";
import arrowRight from "../images/arrow.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const FeatureBlock = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <div className="max-w-7xl mx-auto mt-32">
        <h2
          className="text-[32px] lg:text-[72px] text-[#ffffffc2] font-Inter text-center"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          Key Features Of JustPush
        </h2>
        <p
          className="text-center text-[#ececfb] font-Inter text-[14px] lg:w-1/3 mx-auto"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          Enjoy sending push notifications more easily than ever, and group them
          into topics.
        </p>
        {/* LEFT RIGHT SECTIONS */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-7 items-center p-3">
          <div
            className="mt-1 lg:mt-32 lg:order-1 order-2 p-3"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <h3 className="text-[32px] lg:text-[64px] text-[#ffffffc2] font-Inter leading-[38px] lg:leading-[72px] ">
              Send JustPush messages
            </h3>
            <p className="text-[#d8d5d1cc] font-Inter text-[14px] w-4/5 my-7">
              You can send messages to the JustPush app, triggering push
              notifications for each one. Messages can include text, images,
              call-to-action buttons, and acknowledgment buttons to confirm
              receipt. You can also set an expiration time so the message
              doesn't last indefinitely.
            </p>
            <a
              href=""
              className="font-Inter text-base text-white flex items-center gap-2"
            >
              See the documentation <img src={arrowRight} alt="arrow" />
            </a>
          </div>
          <div
            className="mt-6 lg:mt-32 lg:order-2 order-1"
            data-aos="fade-down"
            data-aos-duration="3000"
          >
            <div className="relative lg:w-4/5 mx-auto">
              <img src={cardImg} alt="img" className="rounded-[20px]" />
              <div className="absolute left-0 right-0 bottom-0 ">
                <img src={cardShortOne} alt="img" />
              </div>
            </div>
          </div>
        </div>
        {/* LEFT RIGHT SECTIONS */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-7 items-center p-3">
          <div
            className="mt-6 lg:mt-32"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <div className="relative lg:w-4/5 mx-auto">
              <img src={cardImg} alt="img" className="rounded-[20px]" />
              <div className="absolute left-0 right-0 bottom-0 ">
                <img src={cardShortTopic} alt="img" />
              </div>
            </div>
          </div>
          <div
            className="mt-1 lg:mt-32 p-3"
            data-aos="fade-down"
            data-aos-duration="3000"
          >
            <h3 className="text-[32px] lg:text-[64px] text-[#ffffffc2] font-Inter leading-[38px] lg:leading-[72px] ">
              Create custom topics
            </h3>
            <p className="text-[#d8d5d1cc] font-Inter text-[14px] w-4/5 my-7">
              Create a new topic and allow other users to join. A topic is a
              chat room where only the creator can send messages. The other
              members can only interact with your messages through the embedded
              call-to-action buttons.
            </p>
            <a
              href=""
              className="font-Inter text-base text-white flex items-center gap-2"
            >
              See the documentation <img src={arrowRight} alt="arrow" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeatureBlock;
